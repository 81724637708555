// Imports

import React from "react"

import { graphql } from 'gatsby'
import SEO from "../components/seo"
import { FaqsHook } from "../hooks/faqs"
import Layout from "../components/layout"
import faqPageStyles from "../styles/pages/faq.module.css"

// Forms

const FAQPage = ({ data }) => {
  const faqs = FaqsHook()
  faqs.sort((a,b) => (a.node.childMarkdownRemark.frontmatter.order > b.node.childMarkdownRemark.frontmatter.order) ? 1 : ((b.node.childMarkdownRemark.frontmatter.order > a.node.childMarkdownRemark.frontmatter.order) ? -1 : 0)); 
  const pageData = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout>
      <SEO title="ACE | Nevada's Leading Cannabis Testing Lab" />
      <div className={faqPageStyles.hero}>
        <div className={faqPageStyles.callToAction}>
          <h3 className={faqPageStyles.title}>{pageData.heroTitle}</h3>
          <p className={faqPageStyles.details}>{pageData.heroSubtitle}</p>
        </div>
      </div>
      <div className={faqPageStyles.faqContainer}>
        <div className={faqPageStyles.faqs}>
          {faqs.map(faq => (
            <>
              <div className={faqPageStyles.faq}>
                <h4 className={faqPageStyles.faq__question}>{faq.node.childMarkdownRemark.frontmatter.question}</h4>
                <p className={faqPageStyles.faq__answer}>
                  {faq.node.childMarkdownRemark.frontmatter.answer}
                </p>
              </div>
            </>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default FAQPage

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "faq-page"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              heroTitle
              heroSubtitle
              heroButtonTitle
          }
        }
      }
    }
  }
}`
